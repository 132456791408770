<template lang="">
    <div>

        <!--begin::Dashboard-->
   <div class="container">

        <div class="row">
            <div class="col col-xl-6 col-md-6 col-sm-6 col-xs-12 mt-2">
                <div class="card card-custom card-stretch" style="height: 332px;">
                    <div class="card-body pt-4">

                        <div class="d-flex align-items-center mt-4">
                            <div class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                                <div class="symbol-label" style="background-image:url('/metronic/theme/html/demo1/dist/assets/media/users/300_21.jpg')"></div>
                                <i class="symbol-badge bg-success"></i>
                            </div>
                            <div>
                                <a href="#" class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">Juan Daniel Salazar</a>
                                <div class="text-muted">Guatemala</div>
                                <div class="mt-2">
                                    <!-- <a href="#" class="btn btn-sm btn-primary font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1">Mi perfil</a> -->
                                    <!-- <a href="#" class="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1">Follow</a> -->
                                </div>
                            </div>
                        </div>

                      <!--   <div class="card-spacer bg-white card-rounded flex-grow-1">
                            <div class="row m-0">
                                <div class="col px-8 py-6 mr-8">
                                    <div class="font-size-sm text-muted font-weight-bold"> Average Sale </div>
                                    <div class="font-size-h4 font-weight-bolder">$650</div>
                                </div>
                                <div class="col px-8 py-6">
                                    <div class="font-size-sm text-muted font-weight-bold"> Commission </div>
                                    <div class="font-size-h4 font-weight-bolder">$233,600</div>
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col px-8 py-6 mr-8">
                                    <div class="font-size-sm text-muted font-weight-bold"> Annual Taxes </div>
                                    <div class="font-size-h4 font-weight-bolder">$29,004</div>
                                </div>
                                <div class="col px-8 py-6">
                                <div class="font-size-sm text-muted font-weight-bold"> Annual Income </div>
                                <div class="font-size-h4 font-weight-bolder">$1,480,00</div></div></div></div> -->

                        <div class="py-9 mt-">
                            <div class="d-flex align-items-center justify-content-between mb-2">
                                <span class="font-weight-bold mr-2">Correo:</span>
                                <span class="text-muted text-hover-primary">jdsalazar@mimail.com</span>
                            </div>
                            <div class="d-flex align-items-center justify-content-between mb-2">
                                <span class="font-weight-bold mr-2">Teléfono:</span>
                                <span class="text-muted">+45998866</span>
                            </div>
                            <div class="d-flex align-items-center justify-content-between mb-2">
                                <span class="font-weight-bold mr-2">Dirección:</span>
                                <span class="text-muted">Melbourne</span>
                            </div>
                             <div class="d-flex align-items-center justify-content-between">
                                <span class="font-weight-bold mr-2">Institución:</span>
                                <span class="text-muted text-hover-primary">SEGEPLAN</span>
                            </div>
                        </div>

                    </div>
                    <!--end:: card-body pt-4 -->
                </div>
                <!--end::card card-custom card-stretch-->
            </div>
            <!-- end:: flex-row-auto w-->



             <div class="col-md-6 col-sm-6 col-xs-12 mt-2">
                

                
                <div class="card card-custom gutter-b d-none"  style="height: 332px; background-size: 100%; background-image: url('/media/svg/shapes/abstract-4.svg');">
                    <div class="card-body d-flex align-items-center">
                        <div>
                            <h3 class="text-dark font-weight-bolder line-height-lg mb-5"> Instrumentos de alianzas </h3>
                            <!-- <p class="text-h6 text-justify text-dark-50">Estrategias registradas del cooperante</p> -->
                            <v-btn color="light-blue-502" class="white--text btn btn-success font-weight-bold px-6 py-3" to="/alianzas/registro">Administrar instrumentos</v-btn>
                        </div>
                    </div>
                </div>

                <template class="d-none">
                    <v-card
                        class="mx-auto"
                        outlined
                        height="280"
                        dark
                        style="background: rgb(255,182,150); background-size: 100%; background-image: url('/media/bg/bg-square.png'), linear-gradient(90deg, rgba(255, 142, 150,1) 0%, rgba(255, 142, 150,1) 68%); "
                    >
                        <v-list-item three-line>
                        <!-- <v-list-item-avatar
                            tile
                            size="150"
                            color="grey"
                        ></v-list-item-avatar> -->

                        <v-list-item-content class="pl-4 mt-4">
                        <!--  <div class="text-overline mb-4">
                            OVERLINE
                            </div> -->
                            <v-list-item-title class="text-h5 mb-1">
                                <h3 class="text-light font-weight-bolder line-height-lg mb-5"> Instrumentos de alianzas </h3>
                                <v-divider></v-divider>
                                <p class="text-light font-weight-bolder line-height-lg mb-5 text-h3"> 45 <small class="text-h5">instrumentos</small> </p>
                                
                                <!-- <p class="text-h6 text-justify text-dark-50">Breve descripción de los proyectos registrados en la modalidad indicada</p> -->
                                <v-btn text outlined to="/alianzas/registro">Administrar instrumentos</v-btn>
                            </v-list-item-title>
                            <!-- <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle> -->
                        </v-list-item-content>

                        
                        </v-list-item>

                        <!-- <v-card-actions>
                            <v-btn
                            outlined
                            rounded
                            text
                            >
                            Button
                            </v-btn>
                        </v-card-actions> -->
                    </v-card>
                    </template>
                

            </div>
        </div>
    </div> <!--fin:: container-->
        
    </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
    
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{ title: "Inicio" }]);
    },
}
</script>
<style lang="">
    
</style>